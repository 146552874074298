import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

const isFunction = (func: any): func is Function => typeof func === 'function';

@Component({
  selector: 'app-ag-grid-link-cell-component',
  template: '<a [routerLink]="link">{{ text }}</a>',
})
export class AgGridLinkCellComponent implements AgRendererComponent {
  link: string | undefined;
  text: string | undefined;

  constructor() {
  }

  agInit(params: ICellRendererParams): void {
    this.refresh(params);
  }

  refresh(params: ICellRendererParams): boolean {
    if (params && params.colDef) {
      const dataParams = params.colDef.cellRendererParams;
      this.link = isFunction(dataParams.link)
        ? dataParams.link(params)
        : params.data?.[dataParams.link];
      //this.text = _.isFunction(dataParams.text) ? dataParams.link(params) : _.get(params.data, dataParams.text);
      //      this.text = `${params.data.first_name} ${params.data.last_name} \n (Email: ${params.data.email})`;
      this.text = `${params.data.first_name} ${params.data.last_name}`;
    }
    return false;
  }
}